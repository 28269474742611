import {mapMutations, mapGetters, mapActions} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {directive as onClickaway} from 'vue-clickaway';
import _ from "lodash";
import {dynamicHead} from "../../mixins/dynamic-head";

export default {
    name: "search",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            allProjects: false,
            allVideos: false,
            allArticles: false,
            allProducts: false,
            searchValue: '',
            isPlayVideo: false,
            videoId: '',
            videosCollection: [],
            modalIsOpen: false,
            videoSlider: {
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                arrows: false,
            },
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'projects', title: this.$t('general.search'), slug: 'search'}
            ],
        }
    },
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        VueSlickCarousel
    },
    watch: {
        'searchList': function () {
            this.$refs.searchTotal.textContent = ''
            if (this.searchValue.length >= 2) {
                let totalSearch = this.getTotal(this.searchList)
                let findCategories = []
                for (let key in this.searchList) {
                    this.searchList[key].data.length ? findCategories.push(`${this.$t('search.' + key)}`) : null
                }
                this.$t('general.searchResults', {
                    'query': this.searchValue,
                    'results_count': totalSearch,
                    'categories': findCategories.join(', ')
                })
                findCategories.length ?
                    this.$refs.searchTotal.textContent = this.$t('general.searchResults', {
                        'query': this.searchValue,
                        'results_count': totalSearch,
                        'categories': findCategories.join(', ')
                    })
                    : this.$refs.searchTotal.textContent = this.$t('general.searchResult.empty')
            }
        }
    },
    computed: {
        ...mapGetters({
            searchList: 'catalog/searchList',
            searchListLoading: 'catalog/searchListLoading',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        }),
    },
    mixins: [dynamicHead],
    created() {
        this.getPageContent('home').then(() => this.setPageItem(this.pageContent))
        this.setRequestFlag(true)
    },
    mounted() {
        this.resetSearchList()
        document.title = `${this.$t('general.search')}`
    },
    methods: {
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            resetSearchList: 'catalog/CLEAR_SEARCH_LIST'
        }),
        ...mapActions({
            searchProduct: 'catalog/CATALOG_SEARCH',
            getPageContent: 'setting/GET_PAGE_CONTENT'
        }),
        redirectToPage(item) {
            item.type === 'landing' ?
                this.$router.push({name: 'landing', params: {slug: item.slug}}) :
                this.$router.push({name: item.slug})
        },
        getTotal(obj) {
            let sum = 0;
            for (let el in obj) {
                if (obj.hasOwnProperty(el)) {
                    sum += parseFloat(obj[el].total);
                }
            }
            return sum;
        },
        search: _.debounce(function () {
            if (this.searchValue.length >= 3) {
                this.searchProduct({text: this.searchValue})
            }
        }, 500),
        openModal(item) {
            this.modalIsOpen = !this.modalIsOpen
            this.videosCollection = []
            this.videosCollection = this.searchList.videos.data

            this.videosCollection = this.videosCollection.filter(el => el.id !== item.id)
            this.videosCollection.unshift(item)
            if (this.modalIsOpen) {
                document.getElementById('html').classList.add('hide')
            } else {
                document.getElementById('html').classList.remove('hide')
            }
        },
        playVideo(item) {

            // this.videosCollection = []
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = item.id
        },
        closeModal() {
            this.isPlayVideo = false
            this.videosCollection = []
            this.modalIsOpen = !this.modalIsOpen
            document.getElementById('html').classList.remove('hide')
        },
        showNext() {
            this.isPlayVideo = false
            this.$refs.galleryCarousel.next()
        },
        showPrev() {
            this.isPlayVideo = false
            this.$refs.galleryCarousel.prev()
        },
        away: function () {
            this.videosCollection = []
            this.modalIsOpen = !this.modalIsOpen
            document.getElementById('html').classList.remove('hide')
        },
    }
}
